:root {
  --primary-background-color: #1d1d1f;
  --secondary-background-color: #111111;
  --button-color-1: #e94560;
}

body {
  background: #000;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}