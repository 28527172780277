:root {
  font-size: 1.1vw;
  --primary-font: 'Nunito Sans', sans-serif;;
  --font-color-1: #f6f5f5;
  --font-color-2: #e94560;
  --font-color-3: #ab68fc;
  --font-color-4: #18a0fb;
  --font-color-5: #00FFA3;
  --font-size-heading-1: 6rem;
  --font-size-heading-2: 3rem;
  --font-size-1: 2rem;
  --font-size-2: 1.5rem;
  --font-size-3: 1rem;
  --font-size-4: 0.8rem;
  --font-size-5: 0.5rem;
}

@media only screen and (max-device-width: 760px) {
  :root {
    font-size: 2vw;
  }
}

@media only screen and (max-device-width: 420px) {
  :root {
    font-size: 3vw;
    text-align: center;
  }
}
